<template>
  <div>
    <div class="f-20 mb-3 d-flex justify-content-between">
      <b> EMAIL TEMPLATE </b>
      <b-button class="btn-preview" @click="openEmailPreview()">
        <font-awesome-icon icon="mobile-alt" class="d-md-none" />
        <span class="d-none d-md-block">
          <font-awesome-icon icon="mobile-alt" class="pointer" />
          <span class="btn-text">Preview</span>
        </span>
      </b-button>
    </div>
    <div class="title-tabs">{{ topic }}</div>

    <b-container class="no-gutters bg-white">
      <b-card class="border-none">
        <b-card-text>
          <b-skeleton-wrapper :loading="isLoading">
            <InputText
              textFloat="Subject"
              placeholder="Subject"
              type="text"
              v-model="subject"
            />
            <EmailEditor
              ref="emailEditor"
              pdpaEmail
              :variableItem="
                MCEVariable.map((el) => {
                  return { label: el, value: '{{' + el + '}}' };
                })
              "
              @onDataChange="(val) => setValue(val)"
              v-model="MCEvalue"
              :template="emailTemplate"
              @clearField="clearField"
            />
          </b-skeleton-wrapper>
        </b-card-text>
      </b-card>
    </b-container>
    <FooterAction routePath="/setting" @submit="exportHtml()" />
    <ModalLoading ref="modalLoading" />
  </div>
</template>
<script>
import ModalLoading from "@/components/modal/ModalLoading";
import EmailEditor from "@/components/inputs/EmailEditor";
import OtherLoading from "@/components/loading/OtherLoading";

export default {
  components: { ModalLoading, EmailEditor, OtherLoading },
  data() {
    return {
      tabIndex: 1,
      tabs: [],
      MCEvalue: "",
      design: "",
      freezeMCEValue: "",
      MCEVariable: [],
      id: 1,
      screenSize: window.innerWidth,
      newTabIndex: 1,
      prevTabIndex: 1,
      isLoading: false,
      emailTemplate: { html: "", css: "" },
      newId: 0,
      topic: "",
      subject: "",
    };
  },
  mounted() {},
  watch: {
    screenSize(val) {
      return val;
    },
  },
  computed: {
    isMobile() {
      return this.screenSize < 1024 ? false : true;
    },
  },
  created() {
    this.getPDPAbyEmail();
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    openEmailPreview() {
      document.documentElement.style.overflow = "hidden";
      this.$refs.emailEditor.openPreviewPopup();
    },
    setValue(val) {
      this.MCEvalue = val.compileHtml;
      this.emailTemplate = val.template;
    },
    async exportHtml() {
      const { template } = await this.$refs.emailEditor.compileEmail();

      var data = {
        value: template.html,
        design: template.css,
      };
      await this.saveForm(data);
    },
    onResize(e) {
      this.screenSize = window.innerWidth;
    },

    async getPDPAbyEmail(condition = true) {
      this.isLoading = true;

      this.id = this.$route.params.id;
      if (this.freezeMCEValue !== this.MCEvalue || condition) {
        const response = await this.axios(`/setting/pdpaemail/${this.id}`);

        this.topic = response.data.detail.email_info.topic;
        if (!response.data.detail.email_info) {
          response.data.detail.email_info = {
            value: "",
            design: "",
          };
        }

        this.emailTemplate = {
          html: response.data.detail.email_info.value
            ? response.data.detail.email_info.value
            : "",
          css: response.data.detail.email_info.design
            ? response.data.detail.email_info.design
            : "",
        };

        this.freezeMCEValue = response.data.detail.email_info.value;
        this.subject = response.data.detail.email_info.subject;
        this.MCEVariable = response.data.detail.variable;
        this.isLoading = false;
      }
    },
    addVariable(val) {
      var ele = document.getElementsByClassName("mce-edit-focus");
      ele[0].getElementsByTagName("p")[0].innerText += `{{${val}}}`;
      // this.MCEvalue += `{{${val}}}`;
    },
    async saveForm(val) {
      var payload = {
        id: this.id,
        subject: this.subject,
        ...val,
      };
      this.$bus.$emit("showLoading");
      return this.axios.post(`/setting/pdpaemail`, payload).then((res) => {
        if (res.data.result) {
          this.successAlert().then(() =>
            this.$router.push("/setting/email-template")
          );

          this.freezeMCEValue = val.value;
        } else {
          this.errorAlert(res.data.message);
        }
        this.$bus.$emit("hideLoading");
        return res;
      });
    },
    clearField() {},
  },
};
</script>
<style class="scoped">
.btn-preview button,
.btn-preview {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  font-weight: 100;
  background-color: white;
}
.f-20 {
  font-size: 20px;
}
.nav-link {
  text-decoration: unset;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--font-color) !important;
  background-color: var(--theme-secondary-color);
}
.tabs-title-spacing {
  /* padding: 0px 15px 0 15px !important; */
  margin: 10px 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.btn-fixed {
  min-width: 150px;
}
.my-actions {
  margin: 0 2em;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}

.right-gap {
  margin-right: auto;
}
iframe {
  height: 80vh !important;
  max-height: 100% !important;
  min-width: unset !important;
}
@media screen and (max-width: 1024px) {
  .tabs-title-spacing {
    border-right: unset;
  }
}

.bg-white ::v-deep .tap-content {
  background-color: #f7f7f7;
}
</style>
